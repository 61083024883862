import {Inject, Injectable, PLATFORM_ID} from '@angular/core';
import { Router } from '@angular/router';
import { HttpClient } from '@angular/common/http';
import { Subject, Observable } from 'rxjs';
import { catchError, filter, retry, takeUntil } from 'rxjs/operators';

@Injectable()
export class AnalyticsService {
    constructor(
        private http: HttpClient,
        private router: Router,
        @Inject(PLATFORM_ID) private platformId
    ) {}

    public sendUtmTags(tags: {[key: string]: string}): void {
      if (!tags || Object.keys(tags).length < 1) {
        // don't send if empty
        return;
      }

        const url = '/api/util/utm/tags';
        this.http.post(url, tags)
          .pipe(
            retry(2), // retry a failed request up to 2 times
            catchError( (err: any) => {
              console.error('Error put utm', err);
              return null;
            }))
          .subscribe();
      }
}
