import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {RouterModule} from '@angular/router';
import {NgbDropdownModule} from '@ng-bootstrap/ng-bootstrap';

import {NotificationButtonModule} from '../notification-button/notification-button.module';
import {PipesModule} from '../pipes/pipes.module';

import {AuthenticatedUserNavbarElementComponent} from './user-nav.component';

import {FaIconLibrary, FontAwesomeModule} from '@fortawesome/angular-fontawesome';
import {
  faAngleDown,
  faChevronLeft,
  faCopyright,
  faDollarSign,
  faPodcast,
  faSignOutAlt,
  faStar,
  faStream,
  faSync,
  faUser,
  faUserPlus,
  faUsers
} from '@fortawesome/free-solid-svg-icons';

import {faStickyNote} from '@fortawesome/free-regular-svg-icons';
import { ImgLazyLoadingModule } from '../image-lazy-loading/image-lazy-loading.module';

@NgModule({
  declarations: [
    AuthenticatedUserNavbarElementComponent
  ],
  imports: [
    CommonModule,
    RouterModule,
    NotificationButtonModule,
    PipesModule,
    NgbDropdownModule,
    FontAwesomeModule,
    ImgLazyLoadingModule
  ],
  exports: [
    AuthenticatedUserNavbarElementComponent
  ]
})

export class AuthenticatedUserNavbarElementModule {
  constructor(library: FaIconLibrary) {
    library.addIcons(faSync,
      faAngleDown,
      faCopyright,
      faChevronLeft,
      faUserPlus,
      faUser,
      faUsers,
      faStar,
      faPodcast,
      faDollarSign,
      faStickyNote,
      faStream,
      faSignOutAlt);
  }
}

