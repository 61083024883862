import {Component, ElementRef, EventEmitter, Input, OnDestroy, OnInit, Output, ViewChild} from '@angular/core';

import {ActivatedRoute, Router} from '@angular/router';

import {StreamService} from '../services/stream_service';
import {NoteService} from '../services/note_service';
import {UserService} from '../services/user_service';
import {NotificationsService} from '../services/notifications_service';

import {NgbModal, NgbModalOptions} from '@ng-bootstrap/ng-bootstrap';
import {ConfirmationModalComponent} from './../../shared/confirmation-modal/confirmation-modal.component';
import {Util} from '../../shared/util/util';

import {Observable, forkJoin} from 'rxjs';

@Component({
  selector: 'notification-button',
  templateUrl: './notification-button.component.html',
  styleUrls: ['./notification-button.scss']
})
export class NotificationButtonComponent implements OnInit, OnDestroy {
  @Input() user: any;
  @Input() isLoggedIn: boolean;
  @Output() logOut: EventEmitter<boolean> = new EventEmitter();
  @ViewChild('notificationModalToggle', {static: false}) notificationModalToggle: any;

  dropdownOpen = false;
  notifications: any[] = [];
  notifications$;
  displayNotificationsLimit = 10;
  count = 0;
  localStreamsData = [];

  getStreamInfoSub: any;

  constructor(
    private userService: UserService,
    private router: Router,
    private streamService: StreamService,
    private notificationsService: NotificationsService,
    private noteService: NoteService,
    private modalService: NgbModal,
    private route: ActivatedRoute,
    private utilities: Util
    ) { }

  ngOnInit() {
    if (!this.userService.isLoggedIn()) {
      return;
    }

    this.notificationsService.getNotifications().then(nList => {
      this.notifications = [];

      if (!nList || nList.length === 0) {
        this.count = 0;
        return;
      } else {
        this.count = nList.length;
      }

      const noteViewId = this.route.snapshot.data['note'] && this.route.snapshot.data['note']['id'] ?
        this.route.snapshot.data['note'] && this.route.snapshot.data['note']['id'] : '';

      const reducedList = Array.from(new Set(nList.slice(0, this.displayNotificationsLimit)));
      const filteredNotifications = noteViewId ? reducedList.filter(n => noteViewId !== n['postId']) : reducedList;

      filteredNotifications.map((notify: any) => {
        switch (notify['notifyType']) {
          case 0:
            this.notifications.push({
              img: '',
              message: `None`,
              id: '',
              notificationId: notify.id,
              url: '/user/notifications'
            });
            break;
          case 1:
            this.notifications.push({
              img: '',
              message: `New private message`,
              id: '',
              notificationId: notify.id,
              url: '/user/notifications'
            });
            break;
          case 2:
            this.notifications.push({
              img: notify.post.thumbnailImage || notify.channel.iconImageUrl || '',
              message: `New post <strong>${notify.post.name}</strong> in <strong>${notify.channel.name}</strong>`,
              title: `New post ${notify.post.name} in ${notify.post.name}`,
              id: notify.channel.id,
              notificationId: notify.id,
              url: `/notes/${notify.post.id}/${this.noteService.urlifyName(notify.post.name || notify.post.urlName || notify.postId)}`
            });
            break;
          case 3:
            this.notifications.push({
              img: '',
              message: `Subscription for <strong>${notify.channel.name}</strong> is expiring`,
              title: `Subscription for ${notify.channel.name} is expiring`,
              id: notify.channel.id,
              notificationId: notify.id,
              url: '/user/notifications'
            });
            break;
          case 4:
            this.notifications.push({
              img: notify.post.thumbnailImage || notify.channel.iconImageUrl || '',
              message: `You received debate invite in <strong>${notify.post.name}</strong>`,
              title: `You received debate invite in ${notify.post.name}`,
              id: notify.channel.id,
              notificationId: notify.id,
              url: `/notes/${notify.post.id}/${this.noteService.urlifyName(notify.post.name || notify.post.urlName || notify.postId)}`
            });
            break;
        }
      });
    }, err => {
      console.error('get notifications error:', err);
    });
  }

  getStreamData(streamId: any): Promise<any> {
    return Promise.resolve(this.localStreamsData.filter(s => s.id === streamId).length > 0 ?
      this.localStreamsData.filter(s => s.id === streamId)[0] : '');
  }

  onClickDropdown(ev: Event) {
    this.dropdownOpen = ev ? true : false;
  }

  onImageLoaded(index: number) {
    this.notifications[index].imageLoaded = true;
  }

  deleteAllNotifications() {
    const modalOptions: NgbModalOptions = {};
    const refModalConf = this.modalService.open(ConfirmationModalComponent, modalOptions);

    refModalConf.componentInstance.confirmationModalTitle = 'Delete all notifications';
    refModalConf.componentInstance.confirmationModalMsg = 'Are you sure you want to delete all notifications?';

    refModalConf.result.then(resp => {
      if (resp) {
        this.notificationsService.deleteAllNotifications().then(() => {
          console.log('Notifications removed!');
        })
        .catch(err => {
          console.log(`error while deleting notifications`, err);
        });
      } else {
        return false;
      }
    })
    .catch(err => {
      console.log(err);
    });
  }

  public toggleNotifications(): void {
    if (this.notificationModalToggle.isOpen()) {
      this.notificationModalToggle.close();
    } else {
      this.notificationModalToggle.open();
    }
  }

  ngOnDestroy() {
    if (this.notifications$) {
      this.notifications$.unsubscribe();
    }

    if (this.getStreamInfoSub) {
      this.getStreamInfoSub.unsubscribe();
    }
  }
}
