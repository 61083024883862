import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { UserService } from '../../shared/services/user_service';
import { StreamService } from '../../shared/services/stream_service';
import { NgbModal, NgbModalOptions } from '@ng-bootstrap/ng-bootstrap';
import { environment } from '../../../environments/environment';
import { DOCUMENT, Location } from '@angular/common';
import { ActivatedRoute } from '@angular/router';
import { JwtHelperService } from '@auth0/angular-jwt';
import { SubscriptionStatus } from '../../shared/types/subscription_status';
import { ConfirmationModalComponent } from '../../shared/confirmation-modal/confirmation-modal.component';


@Component({
  selector: 'subscriptions-table',
  templateUrl: './subscriptions-table.component.html',
  styleUrls: ['./subscriptions-table.component.scss']
})
export class SubscriptionsTableComponent implements OnInit {
  @ViewChild('subscribeToStreamModal', {static: false}) public subscribeToStreamModal: ElementRef;

  mySubscriptions: any = [];
  loadingSubscriptions = true;

  public loadingRedirection = {
    status: false,
    type: '',
    planType: ''
  };

  private subscribeModalRef: any;
  private isLoggedIn = false;

  constructor(
    private streamService: StreamService,
    private route: ActivatedRoute,
    private modalService: NgbModal,
    private location: Location,
    private userService: UserService) {
  }

  public ngOnInit(): void {
    this.loadSubscriptions();
    this.isLoggedIn = this.userService.isLoggedIn();

    if (this.isLoggedIn && this.route.snapshot.queryParams['t']) {
      const urlToken = this.route.snapshot.queryParams['t'];
      const jwtHelper: JwtHelperService = new JwtHelperService();

      try {
        if (jwtHelper.decodeToken(urlToken)) {
          const decodedToken = jwtHelper.decodeToken(urlToken);
          if (decodedToken.meta.channelId) {
            this.streamService.validateSubscription(this.route.snapshot.queryParams['t'], decodedToken.meta.channelId).then(validate => {
              if (validate.status === SubscriptionStatus.Active ) {
                this.loadSubscriptions();
                this.removeUrlParam();
              } else {
                this.showModalMessage(
                  'Error Confirming Payment.',
                  // eslint-disable-next-line max-len
                  'We encountered an issue while confirming your payment. Please try again or contact our customer service for assistance.');
              }
            }, err => {
              console.error('Error while subscription validate', err.error || err);
            })
            .catch(err => {
              console.error('Error while subscription validate', err.error || err);
            });
          }
        }
      } catch (err) {
        console.error('Looks like url doesn`t have proper token or it is a part of application url param.', err);
        // this.showModalMessage(
        //   'Error Confirming Payment.',
        //   'We encountered an issue while confirming your payment. Please try again or contact our customer service for assistance.');
      }
    }
    console.log(this);
  }

  public statusUpdatedEvent(status: any): void {
    // eslint-disable-next-line @typescript-eslint/prefer-for-of
    for (let i = 0; i < this.mySubscriptions.length; i++) {
      const s = this.mySubscriptions[i];
      if (s.channelId === status.channelId && s.planId === status.planId) {
        s._expired = ((new Date()).getTime() > status.expires);
        s.expires = status.expires;
        s.price = status.price;
        break;
      }
    }
  }

  public subscribeToStream(ev: any): void {
    this.loadingRedirection.status = true;
    this.loadingRedirection.type = '';
    this.loadingRedirection.planType = 'stream';
    this.showSubscribeModal();

    const transactRedirectUrl = environment.transactBaseUrl + '/purchase';
    const returnUrl = environment.urlBasePrefix + this.location.path();

    this.streamService.subscriptionRedirectPayment(
      ev.streamId,
      ev.plan,
      returnUrl).then(result => {
      if (result.token) {
        document.location.href = `${transactRedirectUrl}?t=${result.token}`;
      }
    }, err => {
        this.loadingRedirection = {
            status: true,
            type: 'error',
            planType: 'stream'
        };
        console.error('Error while redirection payment process', err);
    });
  }

  public closeSubscribeModal(): void {
    this.subscribeModalRef.close();
  }

  private showSubscribeModal(): void {
    const subscribeModalRef = this.modalService.open(this.subscribeToStreamModal, {
      windowClass: 'subscribe-to-stream-modal',
      size: 'lg'
    });

    subscribeModalRef.result.then(result => {
      console.log(`Closed with:`, result);
      this.loadingRedirection = {
        status: false,
        type: '',
        planType: ''
      };
      this.loadSubscriptions();
    }, reason => {
      console.log(`Dismissed`, reason);
      this.loadingRedirection = {
        status: false,
        type: '',
        planType: ''
      };
      this.loadSubscriptions();
    });
  }

  private loadSubscriptions(): void {
    this.userService.getUserSubscriptionList()
      .then((subscriptions) => {
          this.mySubscriptions = subscriptions.filter(s => s.channelId);
          this.loadingSubscriptions = false;

          this.mySubscriptions
            .map(subscriptionItem => {
              subscriptionItem._expired = ((new Date()).getTime() > subscriptionItem.expires);

              this.streamService.getStreamInfoObservable(subscriptionItem.channelId, true)
                .subscribe(streamInfo => {
                  if (streamInfo) {
                    subscriptionItem._streamName = streamInfo.name;
                    this.streamService.getStreamSubscriptionPlans(subscriptionItem.channelId).then(
                      resp => {
                        const planArr = resp.filter(s => s.id === subscriptionItem.planId);
                        if (planArr && planArr.length === 1) {
                          subscriptionItem._periodStr = planArr[0].periodStr;
                          subscriptionItem._currentPrice = planArr[0].price;
                        }
                      }
                    );
                  }
                },
                err => {
                  const errorCode = err.code || null;
                  console.log('Error getting streamInfo in subscription-table-component', err);

                  switch(errorCode) {
                    case 'DOES_NOT_EXIST':
                      subscriptionItem.error = errorCode;
                      break;
                    default:
                      subscriptionItem.error = 'DOES_NOT_EXIST';
                      break;
                  }
                }
              );
              this.setOwnedStream(subscriptionItem);
            });
        },
        err => {
          console.log('Error', err);
          this.loadingSubscriptions = false;
        }
      );
  }

  private setOwnedStream(item: any): void {
    this.streamService.getUserStreams(true).then((userStreams: any) => {
      const myStream = userStreams.filter(us => item.channelId === us.id);
      if (myStream.length > 0) {
          item._owned = true;
      }
    });
  }

  private showModalMessage(title: string, message: string): void {
    const modalOptions: NgbModalOptions = {};
    const refModalConf = this.modalService.open(ConfirmationModalComponent, modalOptions);

    refModalConf.componentInstance.onlyMessage = true;
    refModalConf.componentInstance.confirmationModalTitle = title;
    refModalConf.componentInstance.confirmationModalMsg = message;

    refModalConf.result.then(resp => {
      if (resp) {
        console.log(resp);
      }
    })
    .catch(err => {
      console.log(err);
    });
  }

  private removeUrlParam(): void {
    const newUrl = window.location.pathname;
    this.location.replaceState(newUrl);
  }
}
