import {Address} from './address';
import {FundsMap} from './funds_map';
import {PermissionsMap} from './permissions_map';
import {PostsMap} from './posts_map';
import {Preferences} from './preferences';
import {UserContactMap} from './user_contact_map';
import {StreamMap} from './stream_map';
import {ThumbnailResolutions} from './thumbnail_resolutions';

export class User {
  public admin: number; // non-zero if global admin
  public apiKey: string;  // If we automate things
  public displayName: string;  // Public to other users and used on site
  public bio: string;  // Public to other users and used on site
  public firstName: string; // private to user
  public lastName: string;  // private to user
  public userName: string; // only lowercase, what is indexed
  public userNameDisp: string; // can be mixed case
  public email1: string; // email registered to user
  public id: string; // user ID
  public xsactUserId: string;
  public profileImageUrl: string; // URL to image
  public profileImgThbs: ThumbnailResolutions;
  public created: number; // user since this date.  Date of creation
  public debaters: UserContactMap; // past people debated with

  public chMember: StreamMap; // stream IDs, that user is a member of (communities)
  public chOwned: StreamMap; // channel IDs, that user is a owner of

  public funds: FundsMap;  // dictionary currency / funds in 100ths of cent
  public permissions: PermissionsMap;  // Permissions user has admin, publicity, etc
  public preferences: Preferences;  //  Preferences
  public savedPosts: PostsMap; // saved notes

  public latitude: number; // default location for searches
  public longitude: number;
  public address: Address; // address that maps to latitude, longitude

  public legalAddress: Address;  // user or company address used for payouts

  constructor(userData?: any) {
    if (userData) {
      const keys = Object.keys(userData);
      keys.forEach( k => {
        this[k] = userData[k];
      });
    }
  }

  public getProfileName(): string {
    if (this.displayName && this.userNameDisp && this.userNameDisp.length > 0) {
      return this.displayName + ' (@' + this.userNameDisp + ')';
    } else {
      return this.displayName || '';
    }
  }

}
