import { NoteService } from './note_service';
import * as Debug from 'debug';
import {Inject, Injectable, PLATFORM_ID} from '@angular/core';
import {isPlatformServer} from '@angular/common';
import {HttpClient, HttpParams} from '@angular/common/http';
import {Router} from '@angular/router';
import {CacheService} from './cache_service';
import {UserService} from './user_service';
import {Util} from '../util/util';

import {Stream} from '../types/stream';
import {Note} from '../types/note';
import {User} from '../types/user';
import {StreamSubscriptionPlan} from '../types/stream-subscription-plan';
import {Address} from '../types/address';

import {environment} from '../../../environments/environment';

import {
  BehaviorSubject,
  Observable,
  Subject,
  catchError,
  forkJoin,
  of,
  map,
  switchMap,
  throwError} from 'rxjs';


import {StreamKind} from '../types/stream_kind';

import { publishReplay, refCount } from 'rxjs';

const debug = Debug('notd:shared:StreamService');

interface StreamMap {
  [key: string]: boolean;
}

@Injectable()
export class StreamService {
  public static streamsUpdated: Subject<string> = new Subject();
  public static streamsFollowUpdated: Subject<string> = new Subject();

  subscriptionStatusUpdateSubject: Subject<any> = new Subject();
  joinedCommunitySub: Subject<any> = new Subject();

  streamsFollowed: StreamMap;
  streamsFollowedList: Stream[];

  followedStreams: Stream[];
  public showSubscribePlansModal: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(null);
  public userStreamCreated$: Subject<Stream> = new Subject();

  // Observable navItem source
  private _streamsFollowedSource = new BehaviorSubject<Stream[]>(undefined);
  // Observable navItem stream
  // eslint-disable-next-line @typescript-eslint/member-ordering
  curSubscriptionList$ = this._streamsFollowedSource.asObservable();

  // eslint-disable-next-line @typescript-eslint/ban-types
  constructor(@Inject(PLATFORM_ID) private platformId: Object,
      private router: Router,
      private http: HttpClient,
      private cacheService: CacheService,
      private userService: UserService,
      private utilities: Util) {

    debug('StreamService constructor');
  }

  getFollowedStreamsObservable(noCache?: boolean): Observable<any> {
    const cachedResult = this.cacheService.getFollowedStreams();

    if (cachedResult && !noCache) {
      return of(cachedResult);
    } else {
      return this.http.get('/api/user/channels/following', {})
        .pipe(
          switchMap((apiResults: any) => {
            if (!apiResults || apiResults.length === 0) {
              return of([]);
            } else {
              const streamsObs = apiResults.map(stream => this.getStreamInfoObservable(stream['id']));

              return forkJoin(streamsObs).pipe(
                map(results => {
                  this.cacheService.setFollowedStreams(results);
                  return results;
                })
              );
            }
          }),
          catchError(err => {
            throw err;
          })
        );
    }
  }

  deleteStream(streamId: string): Promise<void> {
    return this.http.delete('/api/channel/' + streamId + '/delete', {})
    .pipe(
      map(() => {
        this.cacheService.clearMyStreams();
        this.cacheService.clearCache();
      })
      )
      .toPromise();
  }

  public createNewStream(): Promise<Stream> {
    return this.http.get<Stream>( '/api/user/channel/new' , {})
      .toPromise();
  }

  createNewUserStream() {
    return this.http.get( '/api/user/channel/new' , {})
      .pipe(
        map((resp: any) => {
          this.getUserStreams(true).then((res: any) => {
            StreamService.streamsUpdated.next(res);
          });
          return resp;
        }))
      .toPromise();
  }

  updateUserStream(id: string, updatePayload: any) {
    return this.http.post( '/api/channel/' + id + '/profile' , updatePayload)
      .pipe(
        map((resp: any) => {
          this.getUserStreams(true).then(() => {
            StreamService.streamsUpdated.next(resp);

            StreamService.streamsFollowUpdated.next(resp); // FIXME: perhap pass better object to next()
          });
          return resp;
      })
      ).toPromise();
  }

  getUserStreams(force = false): Promise<Stream[]> {
    const cache = this.cacheService.getUserStreams();
    if (isPlatformServer(this.platformId)) {
      // If on server side user is not logged in, so they have no streams
      // return here directly to save a API round trip
      return Promise.resolve([]);
    }
    if (cache && !force) {
      return Promise.resolve(cache);
    } else {
      return this.http.get(environment.apiServerBase + '/api/user/channel/list' , {})
        .toPromise()
        .then((streamList: any) => {
          streamList.map(s => {
            s.iconImageUrl = this.utilities.uncacheUrl(s.iconImageUrl);
          });
          this.cacheService.setUserStreams(streamList);
          return streamList;
        })
        .catch( (err) => {
          if (err.status === 403) {
            return [];
          } else {
            debug('getUserStreams, rethrow error');
            throw err;
          }
        });
    }
  }

  getUserTeamStreams(noCache?: boolean): Promise<{users: User[]; channels: Stream[]}> {
    const cachedData = this.cacheService.getUserTeamStreams();

    if (!noCache && cachedData) {
      return Promise.resolve(cachedData);
    } else {
      return this.http.get( '/api/user/team/channels' , {})
        .toPromise().then((res: {users: User[]; channels: Stream[]}) => {
          this.cacheService.setUserTeamStreams(res);
          return res;
        });
    }
  }

  getStreamsByUserId(userId: any): Promise<any> {
    return this.http.get( '/api/user/' + userId + '/channel/list' , {})
      .toPromise().then(res => res);
  }

  getStreamNotes(streamId: string, query?: {
    sort?: string;
    size?: any;
    fromOffset?: any;
    state?: string;
    // eslint-disable-next-line @typescript-eslint/ban-types
    queryString?: string;}): Promise<Note[] | object> {
    const params: HttpParams = new HttpParams()
      .set( 'q', query.queryString ? query.queryString : '')
      .set( 'sort', (query.sort) ? query.sort : '' )
      .set( 'size', (query.size) ? query.size : '' )
      .set( 'fromOffset', (query.fromOffset) ? query.fromOffset : '' )
      .set( 'state', (query.state) ? query.state : '' );

    return this.http.get(`/api/channel/${streamId}/posts/list`, { params })
      .toPromise();
  }

  getStreamInfo(streamId: string, force = false, redirect404 = false): Promise<Stream> {
    const cache = this.cacheService.getStreamInfo(streamId);

    if (cache && !force) {
      return Promise.resolve(cache);
    } else {
      const options = {
        withCredentials: true
      };

      return this.http.get(environment.apiServerBase + '/api/channel/' + streamId + '/profile' , options)
        .pipe(
          map((channelInfo: any) => {
            this.cacheService.setStreamInfo(channelInfo);
            return channelInfo;
          }),
          catchError(err => {
            if (err.status === 404 && redirect404) {
              this.router.navigate(['404']);
            }
            throw err;
          })
        )
        .toPromise();
    }
  }

  getStreamInfoObservable(streamId: string, force = false, redirect404 = false): Observable<Stream | any> {
    const cache = this.cacheService.getStreamInfo(streamId);

    if (cache && !force) {
      return of(cache);
    } else {
      const options = {
        withCredentials: true
      };

      return this.http.get(environment.apiServerBase + '/api/channel/' + streamId + '/profile' , options)
        .pipe(
          publishReplay(1),
          refCount(),
          map((result: Stream) => {
            if (result && result.id) {
              this.cacheService.setStreamInfo(result);
              return result;
            }
          }),
          catchError(err => {
            if (err.status === 404 && redirect404) {
              this.router.navigate(['404']);
            }
            console.error('Error fetching stream', err);
            return throwError(err);
          })
        );
    }
  }

  getStreamSubscriptionPlans(streamId: string, force = false): Promise<StreamSubscriptionPlan[]> {
    const cache = this.cacheService.getSubscriptionPlans(streamId);

    if (cache && !force) {
      return Promise.resolve(cache);
    } else {
      return this.http.get( '/api/channel/' + streamId + '/subscription/plans' , {})
        .toPromise().then((plans: any) => {
          this.cacheService.setSubscriptionPlans(streamId, plans);
          return plans;
        }, (err) => {
          throw err;
        });
    }
  }

  validateSubscription(token: string, streamId: string) {
    const params = {token};
    const url = '/api/channel/' + streamId + '/subscription/validate';

    return this.http.post(url, params)
      .toPromise().then(
        response => response,
        err => err
    )
    .catch( err => {
      console.error('Error ', err);
    });
  }

  getStreamPayments(streamId: string, days = 1): Observable<any> {
    return this.http.get( '/api/channel/' + streamId + '/payments/summary/DAYS/' + days , {});
  }

  // eslint-disable-next-line @typescript-eslint/ban-types
  getStreamSubscriptionToken(streamId: string, period: string): Promise<object> {
    if (period === 'YEAR') {
      period = 'YEARLY';
    }

    return this.http.get( '/api/channel/' + streamId + '/subscription/token/' + period, {})
      .toPromise().then((resp: any) => {
        this.cacheService.setStreamPurchaseToken(streamId, resp.token);
        return resp.token;
      }, (err) => {
        debug('getStreamSubscriptionToken error', err);
        throw err;
      });
  }

  isSubscribedToStream(streamId: number) {
    if (!this.streamsFollowed) {
      return false;
    }
    // eslint-disable-next-line @typescript-eslint/consistent-type-assertions
    return <boolean> this.streamsFollowed[streamId];
  }

  followStream(streamId: string) {
    return this.http.post( '/api/channel/' + streamId + '/follow' , {})
      .toPromise().then(result => {
        this.getFollowedStreamsObservable(true).subscribe((streams: Stream[]) => {
          this._streamsFollowedSource.next(streams);
        });
      }, (err) => {
        throw err;
      });
  }

  unFollowStream(streamId: string) {
    return this.http.delete( '/api/channel/' + streamId + '/follow' , {})
      .toPromise().then(() => {
        this.getFollowedStreamsObservable(true).subscribe((streams: Stream[]) => {
          this._streamsFollowedSource.next(streams);
        });
      }, (err) => {
        throw err;
      });
  }

  putPrice(streamId: string, priceInCents: string, period: string, active: number) {
    if (period === 'YEAR') {
      period = 'YEARLY'; // PROVISIONAL
    }

    return this.http.put(
      '/api/channel/' + streamId + '/subscription/plan',
      {price: priceInCents, period, active})
      .pipe(
        map(resp => {
          this.getStreamSubscriptionPlans(streamId, true);
          return resp;
          })
      )
      .toPromise();
  }

  getSubscriptionStatus(streamId: string): Promise<any> {
    const url = '/api/channel/' + streamId + '/subscription/status';
    return this.http.get(url).toPromise();
  }

  // eslint-disable-next-line @typescript-eslint/ban-types
  getSubscriptionPlansSettings(): Promise<object> {
    const url = '/api/util/settings/channel/subscription/min_price';
    return this.http.get(url).toPromise();
  }

  // eslint-disable-next-line @typescript-eslint/ban-types
  getStreamTeam(streamId: string | number): Promise<object> {
    return this.http.get(`/api/channel/${streamId}/team`).toPromise();
  }

  public getViewPaidContentStatus(channelId: number | string): Promise<any> {
    return this.http.get(`/api/channel/${channelId}/can-view-paid-content`).toPromise();
  }

  updateAddress(streamId: string, address: string): Promise<Address> {
    return this.http.post( '/api/channel/' + streamId + '/location/address/' + address , {})
      .toPromise().then((result: any) => result,
      (err) => Promise.reject(err)
    );
  }

  // eslint-disable-next-line @typescript-eslint/ban-types
  updateLatitudeLongitude(streamId: string, latitude: number, longitude: number): Promise<object> {
    const payload = {
      lat: latitude,
      lng: longitude
    };

    return this.http.post( '/api/channel/' + streamId + '/location/lat_lng' , payload)
      .toPromise().then(data => data,
      (err) => Promise.reject(err)
    );
  }

  getCommunityMembersList(streamId: string): Observable<any> {
    return this.http.get( '/api/channel/' + streamId + '/members/list' , {});
  }

  getMyStreams(force = false): Observable<Stream[]> {
    const cache = this.cacheService.getMyStreams();

    if (cache && !force) {
      return of(cache);
    }

    let userStreams: Stream[];
    let followed = [];
    const relevantStreamKeys: string[] = ['name', 'numPosts', 'iconImageUrl'];

    return Observable.create(observer => {
      forkJoin([
        this.getFollowedStreamsObservable(),
        this.getUserStreams()]
      ).pipe(
        map(streamsArray => {
          followed = [];
          debug('getMyStreams', streamsArray);
          userStreams = streamsArray[1];

          if (Object.keys(streamsArray[0]).length === 0) {
            this.cacheService.setMyStreams([]);
            observer.next([]);
            observer.complete();
            observer.unsubscribe();
            return;
          }

          streamsArray[0].forEach(f => {
            const ownedStreams = userStreams.filter(u => u.id === f.id);

            if (ownedStreams.length > 0) {
                f._owned = true;
            }

            this.getSubscriptionStatus(f.id).then(
              streamStatus => {
                const status = streamStatus;
                this.getStreamInfo(f.id).then(
                  streamInfo => {
                    const info = streamInfo;
                    if (status && status.channelId) {

                      f._planId = status.planId;
                      f._channelId = status.channelId;
                      f._expires = status.expires;
                    }

                    relevantStreamKeys.forEach(key => f[key] = info[key]);

                    if (info.iconImageUrl) {
                      f.iconImageUrl = this.utilities.uncacheUrl(info.iconImageUrl);
                    }

                    followed.push(f);
                    observer.next(followed);
                    this.cacheService.setMyStreams(followed);

                  },
                  strInfoErr => {
                    console.error('Error getting stream info', strInfoErr);
                  }
                );
              },
              subsStatusErr => {
                console.error('Error getting subscription status', subsStatusErr);
              }
            );
          });
        })
      );
    });

  }

  getStreamImagePath(streamId: string, type: string): string {
    return '/api/channel/' + streamId + '/profile/image/' + type;
  }

  uploadThumbnail(fileToUpload: any, channelId: string): Promise<any> {
    const formData: FormData = new FormData();
    // https://notd.transact.io/api/channel/5718643555958784_1/profile/image/icon

    formData.append('name', fileToUpload.name);
    formData.append('file', fileToUpload);

    return this.http.post<any>(`api/channel/${channelId}/profile/image/icon`, formData).toPromise();
  }

  subscriptionStatusUpdate(status: any) {
    this.subscriptionStatusUpdateSubject.next(status);
  }

  getSubscriptionStatusUpdate() {
    return this.subscriptionStatusUpdateSubject.asObservable();
  }

  // eslint-disable-next-line @typescript-eslint/ban-types
  inviteToCommunity(streamId: string, token: string): Promise<object> {
    return this.http.get( '/api/channel/' + streamId + '/member/invite/new/' + token, {})
      .toPromise()
      .catch(err => {
        console.error('Error inviting to community', err);
        throw err;
      });
  }

  // eslint-disable-next-line @typescript-eslint/ban-types
  getCommunityMemberStatus(channelId: string): Promise<object> {
    return this.http.get(`api/channel/${channelId}/member/status`)
        .toPromise();
  }

  // eslint-disable-next-line @typescript-eslint/ban-types
  removeCommunityMember(streamId: string, userId: string): Promise<void | object> {
    return this.http.delete('/api/channel/' + streamId + '/member/remove/' + userId, {})
      .toPromise().catch((err) => {
        console.error('Error removing Admin', err);
      });
  }

  acceptInvitation(streamId: string, token: string) {
    return this.http.get('/api/channel/' + streamId + '/member/invite/accept/' + token, {})
      .toPromise()
      .catch((err) => {
        console.error('Error accepting invitation', err);
        throw err;
      });
  }

  joinOpenCommunity(streamId: string) {
    return this.http.get('/api/channel/' + streamId + '/member/join' , {})
      .pipe(
        map((resp: any) => {
          StreamService.streamsUpdated.next(resp);
          return resp;
        })
      )
      .toPromise()
      .catch((err) => {
        console.error('Error joining open community', err);
        throw err;
      });
  }

  getPodcastUrl(streamId: string | number) {
    return this.http.get(`/api/channel/${streamId}/audio/podcast-url`)
      .toPromise()
      .catch(err => {
        console.error(err);
        throw err;
      });
  }

  public validateCustomUrlDuplication(url: string): Promise<any> {
    return this.http.get(`/api/search/short-url/channel/${url}`).toPromise();
  }

  public saveCustomUrl(streamId: number | string, url: string): Promise<any> {
    return this.http.post(`/api/channel/${streamId}/short-url/${url}`, {}).toPromise();
  }

  public removeCustomUrl(streamId: number | string): Promise<any> {
    return this.http.delete(`/api/channel/${streamId}/short-url`, {}).toPromise();
  }

  public getStreamIdFromCustomUrl(url: string): Promise<any> {
    return this.http.get(`/api/search/short-url/channel/${url}`)
      .toPromise()
      .catch(err => {
        console.error('Error while fetching stream data');
        throw err;
      });
  }

  public getStreamStats(streamId: string | number): Promise<any> {
    return this.http.get(`/api/streams/${streamId}/owner/analytics/page-views`)
      .toPromise()
      .catch(err => {
        console.error('Error while fetching stream stats.');
        throw err;
      });
  }

  public getStreamViewStats(
    streamId: string | number,
    paid: string,
    interval: string,
    from: number | string,
    to: number | string): Promise<any> {
    return this.http.get(`/api/channel/${streamId}/view-stats/${paid}/${interval}/${from}/${to}`)
      .toPromise()
      .catch(err => {
        console.error('Error while fetching stream view stats.');
        throw err;
      });
  }

  public showSubscrbiePlansModal(): void {
    this.showSubscribePlansModal.next(true);
  }

  public getStreamMembers(streamId: string | number): Promise<any> {
    return this.http.get(`/api/channel/${streamId}/members/list`)
      .toPromise();
  }

  public inviteNewMember(streamId: string | number, email: string): Promise<any> {
    return this.http.get(`/api/channel/${streamId}/member/invite/new/${email}`)
      .toPromise();
  }

  public removeMember(streamId: string | number, userId: string | number): Promise<any> {
    return this.http.get(`/api/channel/${streamId}/member/remove/${userId}`)
      .toPromise();
  }

  public userStreamCreated(stream: Stream): void {
    this.userStreamCreated$.next(stream);
  }

  public subscriptionRedirectPayment(streamId: number | string, period: string, returnUrl: string): Promise<any> {
    return this.http.get(`/api/channel/${streamId}/subscription/token/${period}?method=GET&url=${returnUrl}`)
      .toPromise();
  }

  public restoreStream(streamId: number, name: string): Promise<Stream> {
    return this.http.post<Stream>(`/api/channel/${streamId}/profile`, { name }).toPromise();
  }

  public deleteNoteForever(noteId: number): Promise<any> {
    return this.http.delete(`/api/post/${noteId}`, {}).toPromise();
  }

  getStreamTypes(): Promise<any> {
    return Promise.resolve([
      {
        kind: StreamKind.Default,
        type: 'Default',
        desc: 'A stream for you to post on. Anyone can subscribe to your stream. Only the owner can post.'
      },
      {
        kind: StreamKind.InviteOnly,
        type: 'Private Stream',
        desc: 'Invite required to join. Only the owner of the stream can post.'
      },
      {
        kind: StreamKind.OpenCommunity,
        type: 'Open Community',
        desc: 'Open community streams are an open group. Anyone can join. Members can post.'
      },
      {
        kind: StreamKind.InviteOnlyCommunity,
        type: 'Invite Only Community',
        desc: 'Invite only group. Invite required to join. Members can post. Streams are searchable to help new users find your community.'
      },
      {
        kind: StreamKind.PrivateCommunity,
        type: 'Private Invite Only Community',
        desc: 'Invite only private community. Is not searchable. Members can post.'
      }
    ]);
  }

}
