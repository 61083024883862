<fa-icon class="loader" *ngIf="!readyToSubscribe && !errorMessage" [icon]="['fas', 'sync']" [spin]="true"></fa-icon>
<button *ngIf="readyToSubscribe && !errorMessage"
        class="notd__button--v2 button--green"
        [ngClass]="{'button--disabled': blockSubscribeButton}"
        [disabled]="blockSubscribeButton"
        (click)="subscribePlan()">
        <span *ngIf="loggedIn">{{blockSubscribeButton ? 'Loading...' : label}}</span>
        <span *ngIf="!loggedIn">Log in</span>
</button>
<span class="error-msg" *ngIf="errorMessage">{{errorMessage}}</span>
