import * as Debug from 'debug';
import 'zone.js/plugins/task-tracking';

import {BrowserModule} from '@angular/platform-browser';
import {ErrorHandler, Inject, LOCALE_ID, APP_INITIALIZER, PLATFORM_ID, NgModule, NgZone} from '@angular/core';
import {isPlatformBrowser} from '@angular/common';
import {Router, RouterModule, UrlSerializer} from '@angular/router';

import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {HttpClientModule, HTTP_INTERCEPTORS} from '@angular/common/http';
import {NgbModule} from '@ng-bootstrap/ng-bootstrap';
// import * as Sentry from '@sentry/angular';
// Modules
// import {ScrollToModule} from '@nicky-lenaers/ngx-scroll-to';
import {UserModule} from './user/user.module';
// Components
import {AppComponent} from './app.component';
// Services
import {NoteService} from './shared/services/note_service';
import {StreamService} from './shared/services/stream_service';
import {CacheService} from './shared/services/cache_service';
import {ContactService} from './shared/services/contact_service';
import {CategoriesService} from './shared/services/categories_service';
import {UserService} from './shared/services/user_service';
import {SearchService} from './shared/services/search_service';
import {NotificationsService} from './shared/services/notifications_service';
import {GeoService} from './shared/services/geographic_service';
import {TeamService} from './shared/services/team_service';
import {TagService} from './shared/services/tag_service';
import {NotificationService} from './shared/services/notification_service';
import {MetaService} from './shared/services/meta_service';
import {PwaService} from './shared/services/pwa_service';
import {A2hsService} from './shared/services/a2hs.service';
import { GtmService } from './shared/services/gtm_service';
// Interceptors
import {Util} from './shared/util/util';
import {ApiInterceptor} from './interceptors/api.interceptor';
// Guards
import { AuthGuard } from './shared/route-guards/auth.guard';
import { LoginRedirectGuard } from './shared/route-guards/login-redirect.guard';
import { UserStreamsRedirectGuard } from './shared/route-guards/user-streams-redirect.guard';
import { NoteDraftGuard } from './shared/route-guards/note-draft.guard';
import { AccountRedirectGuard } from './shared/route-guards/account-redirect.guard';
import { StreamIdGuard } from './shared/route-guards/stream-id.guard';
import { ConfirmNoteDeactivateGuard } from './shared/route-guards/note-dectivate.quard';
import { ExistUserGuard } from './shared/route-guards/exist-user.guard';
import { NoteGuideGuard } from './shared/route-guards/note-guide.guard';
import { GuardQueue } from './shared/route-guards/guard-queue.quard';


import {routes} from './app.routes';
import {UserResolve} from './shared/resolvers/user-resolve.service';
import {StreamDetailsResolve, StreamResolve} from './shared/resolvers/stream-resolve.service';
import {NoteResolve} from './shared/resolvers/note-resolve.service';
import {NotePurchaseStatusResolve} from './shared/resolvers/note-purchase-status-resolve.service';
import {ServiceWorkerModule} from '@angular/service-worker';
import {A2hsIosPromptScreenModule} from './shared/a2hs-ios-prompt-screen/a2hs-ios-prompt-screen.module';
import {A2hsBrowserPromptModule} from './shared/a2hs-prompt-button/a2hs-prompt-button.module';
import {AdBlockCheckerModule} from './shared/adblock-checker/adblock-checker.modules';
import {environment} from '../environments/environment';
import { StreamOwnerGuard } from './shared/route-guards/stream-owner.guard';
import { NoteOwnerGuard } from './shared/route-guards/note-owner.guard';
import { NgxEchartsModule } from 'ngx-echarts';
import { CustomUrlSerializer } from './customUrlSerializer';
import { AnalyticsService } from './shared/services/analytics_service';
import * as Sentry from '@sentry/angular-ivy';
const debug = Debug('notd:AppModule');




@NgModule({
  declarations: [
    AppComponent,
  ],
  imports: [
    RouterModule.forRoot(routes, {
    scrollPositionRestoration: 'top',
    enableTracing: false,  // for local debug only
    useHash: false  // keep this for add tracking tags /#/?utm_medium=test
}),
    BrowserModule.withServerTransition({ appId: 'notd-universal' }),
    BrowserAnimationsModule,
    HttpClientModule,
    NgbModule,
    UserModule,
    ServiceWorkerModule.register('/ngsw-worker.js', { enabled: environment.production }),
    A2hsIosPromptScreenModule,
    A2hsBrowserPromptModule,
    AdBlockCheckerModule,
    NgxEchartsModule.forRoot({
      echarts: () => import('echarts')
    })
  ],
  providers: [
    { provide: LOCALE_ID, useValue: 'en' },
    {
      provide: 'sessionStorage',
      useFactory: getSessionStorage
    },
// FIXME(karl) current issue with universal
//    { provide: Sentry.TraceService, useValue: undefined },
    {
      provide: ErrorHandler,
      useValue: Sentry.createErrorHandler({
        showDialog: (environment.urlBasePrefix !== 'https://notd.io'),
      }),
    },
    {
      provide: Sentry.TraceService,
      deps: [Router],
    },
    {
      provide: APP_INITIALIZER,
      // eslint-disable-next-line @typescript-eslint/no-empty-function
      useFactory: () => () => {},
//      deps: [Sentry.TraceService],
      multi: true,
    },

    {
      provide: HTTP_INTERCEPTORS,
      useClass: ApiInterceptor,
      /* Multi is important or you will delete all the other interceptors */
      multi: true
    },
    {
      provide: UrlSerializer,
      useClass: CustomUrlSerializer
    },
    Util,
    UserService,
    CacheService,
    ContactService,
    StreamService,
    NoteService,
    SearchService,
    AuthGuard,
    GuardQueue,
    LoginRedirectGuard,
    NoteGuideGuard,
    AccountRedirectGuard,
    ConfirmNoteDeactivateGuard,
    UserStreamsRedirectGuard,
    ExistUserGuard,
    UserResolve,
    StreamResolve,
    StreamDetailsResolve,
    NoteResolve,
    NotePurchaseStatusResolve,
    CategoriesService,
    NotificationsService,
    GeoService,
    NoteDraftGuard,
    NoteOwnerGuard,
    TeamService,
    TagService,
    NotificationService,
    MetaService,
    PwaService,
    A2hsService,
    StreamIdGuard,
    StreamOwnerGuard,
    GtmService,
    AnalyticsService
  ],
  exports: [RouterModule],
  bootstrap: [AppComponent]
})
export class AppModule {
  constructor(ngZone: NgZone,
      trace: Sentry.TraceService,
      // eslint-disable-next-line @typescript-eslint/ban-types
      @Inject(PLATFORM_ID) protected platformId: Object
    ) {


    if (isPlatformBrowser(this.platformId)) {
      // ignore for now on browser
      return;
    }

    // CONFIGURE how long to wait (in seconds)
    // before the pending tasks are dumped to the console.
    const WAIT_SECONDS = 8;

    console.log(
      `⏳ ... Wait ${WAIT_SECONDS} seconds to dump pending tasks ... ⏳`
    );

    // Run the debugging `setTimeout` code outside of
    // the Angular Zone, so it's not considered as
    // yet another pending Zone Task:
    ngZone.runOutsideAngular(() => {
      setTimeout(() => {
        // Access the NgZone's internals - TaskTrackingZone:
        const taskTrackingZone = (ngZone as any)._inner
          ._parent._properties.TaskTrackingZone;

        // Print to the console all pending tasks
        // (micro tasks, macro tasks and event listeners):
        const tasksToCheck = ['microTask', 'macroTask', 'eventTask'];
        for (const task of tasksToCheck) {
          let zoneTasks = taskTrackingZone.getTasksFor(task);
          if (!zoneTasks || !zoneTasks.length) {
            continue;
          }
          zoneTasks = zoneTasks.filter((zoneTask) => {
            // filter tasks that should be OK to ignore

            if (zoneTask.source.startsWith('TLSSocket')) {
              // Filter
              return false;
            }
            // subscription
            if (zoneTask.source.startsWith('ClientRequest.addListener')) {
              // Filter
              return false;
            }
            // subscription
            if (zoneTask.source.startsWith('IncomingMessage.addListener')) {
              // Filter
              return false;
            }

            // OK
            return true;
          });

          if (!zoneTasks || !zoneTasks.length) {
            continue;
          }

          console.error('👀 Pending tasks in NgZone: 👀');
          console.error(`ERROR pending tasks ${task} num=${zoneTasks.length}`, zoneTasks);

          // Advice how to find the origin of Zone tasks:
          console.error(
            `👀 For every pending Zone Task listed above investigate the stacktrace in the property 'creationLocation' 👆`);
        }

      }, 1000 * WAIT_SECONDS);
    });
  }
 }

export function getSessionStorage() {
  return window.sessionStorage;
}
