import {Injectable} from '@angular/core';
import { ActivatedRouteSnapshot, Router, RouterStateSnapshot } from '@angular/router';
import {UserService} from '../services/user_service';

@Injectable()
export class ExistUserGuard  {
  constructor(private userService: UserService, private router: Router) {}

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Promise<boolean> {
    const userIdent = route.params.userIdent || null;
    if (userIdent) {
      return this.userService.getUserProfile(userIdent).then(user => {
        if (user && user.id !== '') {
          return true;
        } else {
          this.router.navigate(['/404']);
        }
      }).catch(err => {
        this.router.navigate(['/404']);
        return false;
      });
    } else {
      return new Promise(resolve => {
        this.router.navigate(['/404']);
        resolve(false);
      });
    }
  }
}
