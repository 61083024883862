import * as Debug from 'debug';
import {Inject, Injectable, PLATFORM_ID} from '@angular/core';
import {DOCUMENT, isPlatformServer} from '@angular/common';
// import {Observable} from 'rxjs/Observable';
import {BehaviorSubject} from 'rxjs';
import {Util} from '../util/util';
import {HttpClient, HttpHeaders, HttpParams} from '@angular/common/http';

import {ActivatedRoute, NavigationEnd, Router} from '@angular/router';

import {User} from '../types/user';
import { Note } from '../types/note';
import {Bank, TransferMoney, UpdateBank} from '../types/bank';

import { setAuthToken } from '../../interceptors/api.interceptor';

import {CacheService} from '../services/cache_service';

import { environment} from '../../../environments/environment';
import { of, map, Observable} from 'rxjs';
import { catchError, publishReplay, refCount} from 'rxjs/operators';

import * as Sentry from '@sentry/browser';

const debug = Debug('notd:shared:UserService');

@Injectable()
export class UserService {
  // eslint-disable-next-line @typescript-eslint/naming-convention
  redirect_uri: string;
  curUserPromise: Promise<User>;
  curUser: User; // use the convention undefined is not initialized yet,  User.id == 0 is not logged in.

  // Observable navItem source
  private _curUserSource = new BehaviorSubject<User>(undefined);

  // Observable navItem stream
  // eslint-disable-next-line @typescript-eslint/member-ordering
  curUser$ = this._curUserSource.asObservable();

  constructor(
    // eslint-disable-next-line @typescript-eslint/ban-types
    @Inject(PLATFORM_ID) private platformId: Object,
    @Inject(DOCUMENT) private document,
    private http: HttpClient,
    private router: Router,
    private activeRoute: ActivatedRoute,
    // private gtmService: GtmService,
    private cacheService: CacheService,
    private utilities: Util) {
    debug('UserService constructor');
    // FIXME(karl) redirect some other way
    // router.events.filter(event => event instanceof NavigationEnd)
    //   .subscribe(event => {
    //       this.redirect_uri = this.document.location.origin + event['url'];
    //   });
  }

  getProfile(forceRefresh?: boolean): Promise<User> {
    if (this.isLoggedIn() && !forceRefresh) {
      return Promise.resolve(this.curUser);
    } else if (!this.curUserPromise || forceRefresh) {
      // eslint-disable-next-line arrow-body-style
      this.curUserPromise = this.getProfileOnly().then((curUser) => {
        return curUser;
      }, () => this.runOAuth().then((response) => {
          const authorization = response.headers.get('Notd-Auth');
          debug('getProfile runOAuth authorization', authorization);
          setAuthToken(authorization, false);
          // this.gtmService.pushTag({
          //   event: 'login',
          // });
          // eslint-disable-next-line @typescript-eslint/consistent-type-assertions
          if ((<any>window).dataLayer) {
            // eslint-disable-next-line @typescript-eslint/consistent-type-assertions
            (<any>window).dataLayer.push({
              event: 'login',
            });
          }
          return this.getProfileOnly();
        }, (result: any) => {
          if (result.status === 403) {
            debug('Not Authenticated', result);
            // eslint-disable-next-line @typescript-eslint/consistent-type-assertions
            this.curUser = <User>{
              id: ''
            };
            this.cacheService.clearUserCache();
            this.cacheService.clearCache();
            Sentry.configureScope((scope) => scope.setUser({}));
            this._curUserSource.next(this.curUser);
            return this.curUser;
          }

          console.error('Error:', result);
          return null;
        })
        .catch(err => {
          throw err;
        }))
      .catch(err => {
        throw err;
      });
    }

     return this.curUserPromise;
  }

  getUserProfile(userId: any, force = false): Promise<User> {
    const cache = this.cacheService.getUserProfileCache(userId);

    if (isPlatformServer(this.platformId)) {
      // If on server side user is not logged in
      // return here directly to save a API round trip
      return Promise.resolve(null);
    }

    if (cache && !force) {
      return Promise.resolve(cache);
    } else {
      return this.http.get(`/api/user/${userId}/profile`)
        .pipe(
          map((user: User) =>{
            this.cacheService.setUserProfileCache(user);
            return user;
          })
        )
        .toPromise()
        .catch((err) => {
            console.error('Error retrieving user profile', err);
            console.log('current user', this.curUser);
            console.log('getting user id', userId);

            if (this.curUser && this.curUser.id === userId) {
              // eslint-disable-next-line @typescript-eslint/consistent-type-assertions
              this.curUser = <User>{
                id: ''
              };

              this.cacheService.clearUserCache();
              this.cacheService.clearCache();
              this._curUserSource.next(this.curUser);
            }
            throw err;
        });
    }
  }

  getUserProfileObservable(userId: any, force = false): Observable<User | any> {
    // const cache = this.cacheService.getUserProfileCache(userId);
    const cache = false;

    if (isPlatformServer(this.platformId)) {
      // If on server side user is not logged in
      // return here directly to save a API round trip
      return of(null);
    }

    if (cache && !force) {
      return of(cache);
    } else {
      return this.http.get(`/api/user/${userId}/profile`)
        .pipe(
          publishReplay(1),
          map((user: User) => {
            this.cacheService.setUserProfileCache(user);
            return user;
          }),
          refCount(),
          catchError((err) => {
            this.curUser = {
              id: ''
            } as User;
            this.cacheService.clearUserCache();
            this.cacheService.clearCache();
            this._curUserSource.next(this.curUser);
            throw err;
          })
        );
    }
  }

  getUserSubscriptionList(): Promise<any> {
    return this.http.get('/api/user/subscriptions/list')
      .toPromise().catch((err) => {
        console.error('Error retrieving user subscription list', err);
      });
  }

  // eslint-disable-next-line @typescript-eslint/ban-types
  getUserCommentsList(userId: any): Promise<Comment[] | object | void> {
    return this.http.get(`/api/user/${userId}/posts/comments`)
      .toPromise().catch((err) => {
        console.error('Error retrieving user commetns list', err);
      });
  }

  getUserRatingsList(userId: any): Promise<any> {
    return this.http.get(`/api/user/${userId}/posts/ratings`)
      .toPromise().catch((err) => {
        console.error('Error retrieving user ratings list', err);
      });
  }

  getActivityList(fromTime: number): Observable<any> {
    let url = '/api/user/purchases';

    if (fromTime) {
      url += '?after=' + fromTime;
    }

    return this.http.get(url);
  }

  // eslint-disable-next-line @typescript-eslint/ban-types
  getBankList(): Promise<Bank[] | object | void> {
    return this.http.get('/api/user/profile/bank/list')
      .toPromise().catch((err) => {
        console.error('Error retrieving bank list', err);
      });
  }

  // eslint-disable-next-line @typescript-eslint/ban-types
  public getUserNotes(userIdent: number | string): Promise<Note[] | object | void> {
    return this.http.get(`/api/user/${userIdent}/posts/list`)
      .toPromise();
  }

  putUserName(user: any) {
    this.cacheService.removeUserProfileCache(user);

    if (user.username) {
      this.cacheService.removeUserProfileByUsernameCache(user.username);
    }

    return this.http.put('/api/user/profile/username/' + user.userName , {})
      .pipe(
        map((resp: any) => {
          this.cacheService.setUserProfileCache(user);
          this.curUser = resp;
          return resp;
        })
      )
      .toPromise();
  }

  updateUser(newUserInfo: User, localOnly?: boolean) {
    const payload = Object.assign(Object.assign({}, this.curUser), newUserInfo);

    if (!localOnly) {
      return this.http.post('/api/user/profile', payload, {})
        .toPromise().then((response: any) => {
            if (response.profileImageUrl) {
              response.profileImageUrl = this.utilities.uncacheUrl(response.profileImageUrl);
            }

            this.curUser = response;
            this._curUserSource.next(this.curUser);

            // eslint-disable-next-line @typescript-eslint/consistent-type-assertions
            return <User>this.curUser;
          },
          err => {
            throw err;
          });
    } else {
      Object.assign(this.curUser, newUserInfo);
      this._curUserSource.next(this.curUser);
    }
  }
/* Dec 28, 2019 karl thinks this is not used.

  uploadProfileImage() {
    const uploader = new FileUploader({ url: '/api/user/profile/image', disableMultipart: false, removeAfterUpload: true });
    return {
      uploader: uploader,
      execute: (file) => {
        return new Promise<Response>((resolve, reject) => {
          uploader.onCompleteItem =
            (item, response, status, headers) => {
              debug('onCompleteItem', item, response, status, headers);
              const responseParsed = JSON.parse(response);

              if (responseParsed.status && responseParsed.status !== 200) {
                reject(responseParsed);
              } else {
                resolve(responseParsed);
              }
            };
          uploader.onErrorItem =
            (item, response, status, headers) => {
              debug('onErrorItem', item, response, status, headers);
              reject(JSON.parse(response));
            };

          uploader.uploadAll();
        });
      }
    };
  }
*/
  uploadUserAvatar(fileToUpload: File): Promise<any> {
    const formData: FormData = new FormData();

//    formData.append('name', fileToUpload.name);

    // FIXME we should send real file name.
    formData.append('file', fileToUpload, 'avatar.png');

    return this.http.post<any>(`/api/user/profile/image`, formData).toPromise();
  }

  isLoggedIn(): boolean {
    if (this.curUser === undefined) {
      return undefined;
    }
    return this.curUser.id !== '';
  }

  login(register?: boolean, customRedirectUrl?: string): Promise<any> {
    return this.http.get('/api/oauth/state')
      .toPromise()
      .then((response: any) => {
        debug('got Oauth state', response);
        console.log('got Oauth state', response);

        const params: URLSearchParams = new URLSearchParams();
        const transactUrl = environment.transactBaseUrl + '/oauth2';

        if (!response['client_id'] || response['client_id'].length < 1) {
          console.error('INVALID OAUTH response:', response);
          // FIXME(karl) remove the alert, but use the Error logging service.
          alert('Please contact support, client_id is invalid');
          return;
        }
        const maxUrlLength = 200; // prevent from being too long.
        params.set('response_type', 'code');
        params.set('client_id', response['client_id']);
        params.set('state', response['state']);
        params.set('register', (register) ? '1' : '');
        params.set('scope', 'email,name');
        if (customRedirectUrl) {
          params
            .set(
              'redirect_uri',
              `${this.document.location.origin}/${customRedirectUrl}`);
        } else {
          params.set('redirect_uri', this.redirect_uri ?
            this.redirect_uri.substring(0, maxUrlLength) : this.document.location.href.substring(0, maxUrlLength));
        }
        debug('params', params);
        debug('URL: ' + params.toString());
        document.location.assign(transactUrl + '?' + params.toString());
      })
      .catch( err => {
        console.error('Error ', err);
      });
  }

  logout(): Promise<any> {
    return this.http.get('/api/user/logout')
      .toPromise().then(() => {
        // eslint-disable-next-line @typescript-eslint/consistent-type-assertions
        this.curUser = <User>{
          id: ''
        };
        this.cacheService.clearUserCache();
        this.cacheService.clearCache();
        this._curUserSource.next(this.curUser);
        this.curUserPromise = null;
        setAuthToken(''); // clear on logout
      }, (err) => {
        console.error(err);
      });
  }

  runOAuth(): Promise<any> {
    if (this.isLoggedIn()) {
      return Promise.resolve(null);
    }
    const code = this.getParameterByName('code');
    const state = this.getParameterByName('state');

    if (!code || code.length < 10) {
      // not set
      return Promise.reject({ status: 403 });
    }
    if (!state || state.length < 10) {
      // not set
      return Promise.reject({ status: 403 });
    }

    const data = {
      state,
      code,
      // eslint-disable-next-line @typescript-eslint/naming-convention
      redirect_uri:  typeof window !== 'undefined' ? (window.location.origin + window.location.pathname) : '',
    };


    const httpOptions = {
      // eslint-disable-next-line @typescript-eslint/naming-convention
      headers: new HttpHeaders({ 'Content-Type': 'application/json' }),
      // eslint-disable-next-line @typescript-eslint/prefer-as-const
      observe: 'response' as 'response',
    };


    return this.http.post('/api/oauth/code/token', data, httpOptions)
      .toPromise();
  }

  setLocationAddress(address: string): Promise<any> {
      return this.http.post(`/api/user/location/address/${address}`, {})
        .toPromise()
        .catch(err => console.error('Error while saving user location', err.error || err));
  }

  setLocationCords(lat: number, lng: number): Promise<any> {
    const params = new HttpParams()
      .set('lat', lat.toString())
      .set('lng', lng.toString());

      return this.http.post(`/api/user/location/address/lat_lng`, params)
        .toPromise()
        .catch(err => console.error('Error while saving user location', err.error || err));
  }

  listBanks(): Promise<any> {
    return this.http.get( '/api/user/profile/bank/list', {}).toPromise();
  }

  // eslint-disable-next-line @typescript-eslint/ban-types
  putUserPreference(key: string, value: string | any): Promise<User | object> {
    return this.http.put('/api/user/profile/preferences/' + key + '/' + value , {})
      .toPromise()
      .catch(err => {
        console.error('Error setting user preferences', err);
        throw err;
      });
  }

  getSavedNotes(): Observable<any> {
    return this.http.get('/api/user/saved-posts/list', {});
  }

  getUserImagePath(): string {
    return '/api/user/profile/image';
  }

  getTaxDocPath(): string {
    return '/api/user/profile/legal/tax_document';
  }

  getIdentityDocPath(): string {
    return '/api/user/profile/legal/identity_document';
  }

  updateBank(params: UpdateBank): Promise<any> {
    return this.http.post('/api/user/profile/bank/info', params).toPromise();
  }

  updateLegalInfo(params: any): Promise<any> {
    return this.http.post('/api/user/profile/legal/info', params).toPromise();
  }

  transferMoney(params: TransferMoney): Promise<any> {
    return this.http.post('/api/user/payout/request/bank', params).toPromise();
  }

  getBankFee(bankId: string): Promise<any> {
    return this.http.get('/api/user/profile/bank/' + bankId + '/transfer/fee', {}).toPromise();
  }

  listCommunities(): Observable<any> {
    return this.http.get('/api/user/channel/member/list', {})
      .pipe(
        catchError(err => {
          //TODO: (karl) Why catch and throw and do nothing?
          throw err;
        })
      );
  }

  public getBlockedUsers(streamId: number | string): Promise<any> {
    return this.http.get(`/api/channel/${streamId}/members/blocked/list`).toPromise();
  }

  public blockCommentUser(streamId: number | string, userId: number | string, comments: string): Promise<any> {
    const params: HttpParams = new HttpParams()
        .set('comments', comments);
    return this.http.put(`/api/channel/${streamId}/member/block/user/${userId}`, params).toPromise();
  }

  public removeBlockedUser(streamId: number | string, userId: number | string): Promise<any> {
    return this.http.delete(`/api/channel/${streamId}/member/block/user/${userId}`).toPromise();
  }

  public deleteUser(): Promise<any> {
    return this.http.delete(`/api/user/profile/delete/start`).toPromise();
  }

  public confirmUserRemoval(token: string | number): Promise<any> {
    return this.http.put(`/api/user/profile/delete/confirm/${token}`, {}).toPromise();
  }

  public getParameterByName(name, url?) {
    if (!url) {
      url = window.location.href;
    }
    name = name.replace(/[\[\]]/g, '\\$&');
    const regex = new RegExp('[?&]' + name + '(=([^&#]*)|&|#|$)');
        const results = regex.exec(url);
    if (!results) {
      return null;
    }
    if (!results[2]) {
      return '';
    }
    return decodeURIComponent(results[2].replace(/\+/g, ' '));
  }

  public getUserProfileByUsername(username: string): Promise<User> {
    const cachedProfile = this.cacheService.getUserProfileByUsernameCache(username);

    if (cachedProfile) {
      return Promise.resolve(cachedProfile);
    } else {
      return this.http.get<User>(`/api/user/${username}/profile`, {})
        .pipe(
          map((user: User) =>{
            this.cacheService.setUserProfileByUsernameCache(user);
            return user;
          })
        )
        .toPromise()
        .catch(error => {
          console.error('Error while getting user profile');
          throw error;
        })
    }
  }

  public getProfileOnly(): Promise<User> {
    const httpOptions = {
      withCredentials: true
    };

    return this.http.get('/api/user/profile', httpOptions)
      .toPromise().then((response: User) => {

        if (response.profileImageUrl) {
          response.profileImageUrl = this.utilities.uncacheUrl(response.profileImageUrl);
        }

        this.curUser = response;

        Sentry.configureScope((scope) => {
          scope.setUser({
            email: this.curUser.email1,
            id: this.curUser.id + ''
          });
        });

        this._curUserSource.next(this.curUser);
        // eslint-disable-next-line @typescript-eslint/consistent-type-assertions
        return <User>this.curUser;
      }, (err) => {
        Sentry.configureScope((scope) => scope.setUser({}));
        throw err;
      })
      .catch( err => {
        console.log(err.error || err);
        Sentry.configureScope((scope) => scope.setUser({}));
        throw err;
      });
  }
}
