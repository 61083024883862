import {Injectable} from '@angular/core';
import { ActivatedRouteSnapshot, Router, RouterStateSnapshot } from '@angular/router';
import { User } from '@sentry/types/types/user';
import { resolve } from 'dns';
import { StreamService } from '../services/stream_service';
import { UserService } from '../services/user_service';
import { Stream } from '../types/stream';

@Injectable()
export class StreamOwnerGuard  {
  constructor(
    private router: Router,
    private streamService: StreamService,
    private userService: UserService
  ) {}

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Promise<boolean> {
    const streamId = route.params['id'];
    return this.userService.getProfile().then((user: User) => {
      const streamsOwned = user.chOwned;

      for (const [key, value] of Object.entries(streamsOwned)) {
        if (value === streamId) {
          return true;
        }
      }

      this.router.navigate(['streams', streamId, 'view', 'stream name']);
      return false;

    });
  }
}
